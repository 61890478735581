import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { store } from './stores/store';
import { Viewer } from './Viewer/Viewer';
import { ViewerProvider } from './hooks/useViewer/useViewer';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

declare global {
  interface Window {
    _env: {
      SENTRY_ENV?: string;
      SENTRY_DSN_FRONT_END?: string;
      GOOGLE_TRACKING_CODE: string;
      MAP_BOX_ACCESS_TOKEN: string;
    };
    Cypress: unknown;
    onCypressInit?: () => void;
    APP?: {
      customViewer?: {
        instance?: {
          setPano?: (panoid: string, sceneFade: string) => void;
        };
      };
    };
  }
}

Sentry.init({
  environment: window._env.SENTRY_ENV || 'development',
  dsn: window._env.SENTRY_DSN_FRONT_END,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (window.Cypress) {
  window.onCypressInit?.();
}

Viewer.initViewer();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ViewerProvider>
      <App />
    </ViewerProvider>
  </Provider>
);

reportWebVitals(console.log);
