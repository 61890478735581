import { useEffect } from 'react';

import useViewer from '../../hooks/useViewer/useViewer';

// Used to intercept old APP API used in floorplans widget
const InterceptApp = () => {
  const { viewer } = useViewer();

  useEffect(() => {
    Object.defineProperty(window, 'APP', {
      configurable: true,
      get() {
        return {
          customViewer: {
            instance: {
              setPano: (panoid: string) => {
                viewer?.setPano(panoid);
              },
            },
          },
        };
      },
    });
  }, [viewer]);

  return null;
};

export default InterceptApp;
