import { useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

import { MEET_URL } from '../../../../constants';
import { SHARE_LIVE_ID } from '../../../../constants/ids';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useURLParam, {
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';
import useUTMParams from '../../../../hooks/useUTMParams/useUTMParams';
import appendParamsToUrl from '../../../../utils/appendParamsToUrl/appendParamsToUrl';
import useSource from '../../../../utils/useSource/useSource';

import ControlGroup from '../../../../components/ControlGroup/ControlGroup';
import { ReactComponent as ChatIcon } from './assets/chat.svg';

import styles from './Chat.module.scss';

interface ChatProps {
  clientId: string;
  themeId: string;
}

export default function Chat({ clientId, themeId }: ChatProps) {
  const [visible, setVisible] = useState(false);
  const onClick = () => setVisible(!visible);
  const onClose = () => setVisible(false);
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const admin = useURLParam(OverlayURLParam.ADMIN);
  const trackingToken = useURLParam(OverlayURLParam.TRACKING_TOKEN);
  const tourId = useURLParam(OverlayURLParam.TOUR);
  const isMobile = useIsMobile();

  const meetingUrlWithAdditionalParams = appendParamsToUrl(
    `${MEET_URL}/${themeId}/${clientId}/${tourId}`,
    {
      ...utmParams,
      sourceId,
      admin,
      tracking_token: trackingToken,
    }
  );

  const menuRef = useRef<HTMLDivElement>(null);
  const containerAnimation = useSpring({
    translatey: visible
      ? menuRef.current!.clientHeight * (isMobile ? -1 : 1) -
        (isMobile ? 0 : 60)
      : 0,
    opacity: visible ? 1 : 0,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, onClose);

  return (
    <div className={styles.container} ref={containerRef}>
      <animated.div
        className={styles.buttonContainer}
        style={containerAnimation}
        ref={menuRef}
      >
        <a
          data-cy="share-live"
          href={meetingUrlWithAdditionalParams.toString()}
          className={styles.button}
          target="_blank"
          title="Click here to start your virtual meeting"
          rel="noreferrer"
          id={SHARE_LIVE_ID}
        >
          Share live
        </a>
        <button
          className={styles.button}
          onClick={() => console.log('i work send message')}
        >
          Send a message
        </button>
        <button
          className={styles.button}
          onClick={() => console.log('i work schedule tour')}
        >
          Schedule a tour
        </button>
      </animated.div>
      <ControlGroup
        className={styles.icon}
        controls={[
          {
            display: <ChatIcon aria-label="Chat Icon" data-cy="chat" />,
            onClick: onClick,
          },
        ]}
      />
    </div>
  );
}
